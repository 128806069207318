<template>
  <div class="sampledetail">
    <div class="title">基础信息</div>
    <van-cell-group class="mt10">                
      <div v-for="item in typeList" :key="item.id">   
          <van-cell title="报检类型" v-if="item.id == dataList.sample_type" :value="item.type_name" />
      </div>
      <van-cell title="报检编号" :value="dataList.inspect_code" />
      <van-cell title="报检时间" :value="dataList.inspect_time" />
    </van-cell-group>
    <div class="title">报检员信息</div>
    <van-cell-group class="mt10">
      <van-cell title="报检员ID" :value="dataList.inspect_emp_id" />
      <van-cell title="报检员姓名" :value="dataList.inspect_emp_name" />
      <van-cell title="报检员部门" :value="dataList.inspect_dep_name" />
    </van-cell-group>
    <div class="title">样品信息</div>
    <van-cell-group class="mt10">
      <van-cell title="产品编号" :value="dataList.sample_code" />
      <van-cell title="产品名称" :value="dataList.sample_name" />
      <van-cell title="产品规格型号" :value="dataList.sample_specs" />
      <van-cell title="报检批次" :value="dataList.inspect_batch" />
      <van-cell title="产品供应商" :value="dataList.supplier_name" />
      <van-cell title="合同编号" :value="dataList.contract_no" />
      <van-cell title="报检重要说明" :value="dataList.inspect_remark" />
      <van-cell title="样品附件信息" /> 
      <van-cell-group class="mt10">
        <span style="text-align: center; width: 33.3333333333%;display: inline-block;" v-for="item in attachList" :key="item">
          <van-image width="100" height="100" :src="item" @click="bigImg()" />
        </span>              
      </van-cell-group>               
    </van-cell-group>
    <div v-show="dataList.check_status > 1">
      <div class="title">检测情况</div>
        <van-cell-group class="mt10">
          <van-cell title="初检时间" :value="dataList.check_time" />          
          <van-cell title="检测设备" :value="equipmentName" />
          <van-cell title="检样员" :value="dataList.check_emp_name" />
          <!-- <van-cell title="是否复检" v-show="dataList.is_inspect_recheck == 0" value="否" /> -->
          <van-cell title="是否复检" value="是" />
          <van-cell title="复检时间" v-show="dataList.is_inspect_recheck > 0" :value="dataList.inspect_recheck_time" />      
          <van-cell title="复检员" :value="dataList.recheck_emp_name" />
          <van-cell title="复检时间" :value="dataList.recheck_time" />
          <van-cell title="检测结果" v-show="dataList.conclusion == 1" value="合格" />
          <van-cell title="检测结果" v-show="dataList.conclusion == 0" value="不合格" />
        </van-cell-group>
    </div>
    <div v-show="dataList.check_status > 1">
      <div class="title">检测项目</div>
      <div style='position:relative;'>
        <van-icon class="status" v-if="dataList.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
        <van-icon class="status" v-if="dataList.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/> 
        <!-- <img v-show="dataList.conclusion == 1" src="@/assets/hg.png" alt="合格" class="status" />
        <img v-show="dataList.conclusion == 0" src="@/assets/bhg.png" alt="不合格" class="status" /> -->
        <ve-table :columns="columns" :table-data="dataList.check_items" :border-x="true" :border-y="true" /> 
      </div>
    </div>    	
    <div v-show="dataList.check_status > 3 ">
      <div class="title">申请情况</div>
      <van-cell title="申请人"  input-align="right" readonly :value="dataList.inspect_emp_name" />
      <van-cell title="申请原因"  input-align="right" readonly :value="dataList.concession_acceptance_reason" /> 
      <van-cell title="申请时间"  input-align="right" readonly :value="dataList.concession_acceptance_time" /> 
      <div class="title">审批情况</div>
      <van-cell title="审批人"  input-align="right" readonly :value="dataList.approval_emp_name" />
      <van-cell title="备注"  input-align="right" readonly v-model="approvalRemark" />
    </div>
    <div class="mt10 btn-r">
      <van-button class="ml5" round plain size="small" type="default" @click="toList">返回列表</van-button>
      <!-- <van-button class="ml5" round plain size="small" type="default" v-print="print" v-show="dataList.check_status == 5 || dataList.check_status == 3">打印报告</van-button> -->
    </div>
    <div id="printMe">
      <h3 class="tac">{{dataList.type_name}}化验单</h3>
      <table class="table-cle" border="1px; solid #000;" width="100%" height="50" style="background: #fafafa;">
        <tr style="border-bottom: 1px solid #000;">
          <td><span>原料名称</span></td>
          <td colspan="2"><span>{{dataList.sample_name}}</span></td>
          <td><span>来料单位</span></td>
          <td colspan="2"><span>{{dataList.supplier_name}}</span></td>
        </tr>
        <tr>
          <td><span>批号</span></td>
          <td><span>{{dataList.inspect_batch}}</span></td>
          <td><span>采样人</span></td>
          <td><span>{{dataList.inspect_emp_name}}</span></td>
          <td width="50px"><span>数量</span></td>
          <td><span></span></td>
        </tr>        
      </table>
      <ve-table class="item"
        :columns="proColumns"
        :table-data="dataList.check_items"
        :border-around="true"
        :border-x="true"
        :border-y="true"
        :cell-span-option="cellSpanOption"
        style="border: 1px solid #000;border-top: none;"
      />
      <table class="table-cle" border="1px" width="100%" height="25" style="margin-top: -1px;">
        <tr style="background: #fafafa;">
          <td width="50px"><span>备注</span></td>          
          <td colspan="6"><span>{{dataList.inspect_remark}}</span></td>           
        </tr>           
      </table>
      <van-row style="background-color: #fafafa;">
         <van-col span="4"></van-col>        
        <van-col span="4">
          <van-cell style="background-color: #fafafa;" title="检验：" :value="dataList.check_emp_name" />
        </van-col>       
        <van-col span="4">
          <van-cell style="background-color: #fafafa;" title="复检：" :value="dataList.recheck_emp_name" />
        </van-col>
        <van-col span="10">
          <van-cell style="background-color: #fafafa;" title="化验时间：" :value="dataList.recheck_time" />
        </van-col>
        <van-col span="2">  </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Cell, CellGroup, Step, Steps, Toast, Image as VanImage, ImagePreview, Col, Row, Field,Button,Icon  } from 'vant'

Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(VanImage)
Vue.use(ImagePreview)
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Button);
Vue.use(Icon);
export default {
  data() {
    return { 
      tzid: '',
      equipmentName: '',
      typeList: [],
      attachList: [], //附件
      approvalRemark: '', //让步备注
      dataList:{
        check_items:[],
        inspect:{
          type: {}
        }
      },
      columns: [
          { field: "check_name", key: "a", title: "检测项", align: "center" },
          { field: "check_standard", key: "b", title: "标准指标", align: "center" },
          { field: "check_value", key: "c", title: "检测指标", align: "center" },
          { field: "single_conclusion", key: "d", title: "单项结论", align: "center" },
      ],
      cellSpanOption: {
          bodyCellSpan: this.bodyCellSpan,
        },
      proColumns: [
          { field: "check_name", key: "a", title: "检测项", align: "center" },
          { field: "check_standard", key: "b", title: "标准指标", align: "center" },
          { field: "check_value", key: "c", title: "检测指标", align: "center" },
          { field: "single_conclusion", key: "d", title: "单项结论", align: "center" },
          { field: "conclusion", key: "e", title: "结论", align: "center" }
        ],
    proTableData: [],
      print: {
        id: 'printMe',
        popTitle: '配置页眉标题', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '预览的标题', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高               
        standard: '',
        extarCss: ''
      }       
    }
  },
  created: function () {     
    if(this.$route.query.tzid) {
      this.tzid = this.$route.query.tzid
    } else {
      this.tzid = localStorage.getItem('tzid')
    }     
    this.getDetail(localStorage.getItem('tzid')) 
    this.getTypeList()
    this.getFjList(localStorage.getItem('tzid'))  
  },
  methods: {
    getDetail(tzid) {      
      var that = this
      this.$get('statistics/get-check-record-list', 'api', { check_id: tzid}).then(res => {         
        var data = res.data.list[0]        
        this.dataList = data    
        data.check_items.forEach(ele => {
          if(data.conclusion == '0') {
            ele.conclusion = '不合格'
          } else {
            ele.conclusion = '合格'
          }          
        });
        data.inspect_time = that.$common.initDate(data.inspect_time)
        data.check_time = that.$common.initDate(data.check_time)
        // data.concession_acceptance_time = that.$common.initDate(data.concession_acceptance_time)
        data.inspect_recheck_time = that.$common.initDate(data.inspect_recheck_time) 
        data.recheck_time = that.$common.initDate(data.recheck_time)   
        this.dataList = data
        this.approvalRemark = data.approval_remark  
        var equipmentName = []
        data.equipment_items.forEach(ele => {
          equipmentName.push(ele.equipment_name)
        })
        this.equipmentName = equipmentName.join(',')
        return        
      }, err => {
        console.log(err)
        // Toast.fail(err.message);
      })
    },
    // 获取检测类型
    getTypeList() {
        this.$get('sample-type/get-sample-type-list', 'api', { page: 1,offset: 100 }).then(res => {
          const datas = res.data.list                 
          this.typeList = datas
        }, err => {
            console.log(err)
            
        })
    },
    // 获取附件信息
    getFjList(id) {        
        this.$post('statistics/get-sample-attach', 'api', { id: id }).then(res => {
            const datas = res.data.attach                    
            this.attachList = datas
        }, err => {
            console.log(err)
            
        })
    },
    // 查看大图
    bigImg () {
      ImagePreview({
        images: this.attachList,
        showIndex: true,
        loop: true,
        closeable: true       
      })
    },
    bodyCellSpan({ row, column, rowIndex }) {      
      if (column.field === "conclusion") {
        if (rowIndex === 0) {
          return {
              rowspan: this.dataList.check_items.length,
              colspan: 1,
          };
        }else if (rowIndex >= 1) {
            return {
                rowspan: 0,
                colspan: 0,
            };
        }           
      } 
  },
  // 返回列表
  toList() {
    this.$router.push({
      path: '/dataLists'          
    })
  },
    previewBeforeOpenCallback () { 
      // console.log('正在加载预览窗口！')
    }, // 预览窗口打开之前的callback
    previewOpenCallback () { 
      // console.log('已经加载完预览窗口，预览打开了！') 
    }, // 预览窗口打开时的callback
    beforeOpenCallback () { 
      // console.log('开始打印之前！') 
    }, // 开始打印之前的callback
    openCallback () { 
      // console.log('执行打印了！') 
    }, // 调用打印时的callback
    closeCallback () { 
      // console.log('关闭了打印工具！') 
    }, // 关闭打印的callback(无法区分确认or取消)
    clickMounted () { 
      // console.log('点击v-print绑定的按钮了！') 
    },
  }

}
</script>

<style scoped>
.tac {
  text-align: center;
}
.status{
  position: absolute;
  top: -30px;
  right: 0px;
  width: 80px;
  height: 80px;
  z-index: 999;
 }
.sampledetail{
  padding-bottom: 60px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: 12px;
  color: #000000d9;
}
.table-cle td {
  padding: 6px 2px;
  text-align: center;
  border-right-color: #000; 
}
#printMe {
  height: 0; 
  overflow: hidden;
  padding: 0 10px !important;
}
@media print {
  #printMe {
    height: auto;
    display: block !important;
  }
  .item{
    display: block !important;
  }
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th,#printMe >>> .ve-table .ve-table-container .ve-table-border-y td {
  border-right: 1px solid #000 !important;
  border-bottom-color: #000 !important;
  font-size: 12px;
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th:last-child, #printMe >>> .ve-table-body tr:nth-child(2) td:last-child{
  border-right: none !important;
}
#printMe >>> .ve-table-body tr:nth-child(2) td:last-child{
  border-bottom: none !important;
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th {
  border-bottom-color: #000 !important;
}
#printMe .van-cell__value, #printMe .van-cell {
  color: #000000d9;
}
#printMe .van-cell__title {
  text-align: right;
}
#printMe .van-cell__value {
  text-align: left;
}
/*去除页眉页脚*/
  @page{
    size:  auto;   /* auto is the initial value */
    margin: 3mm;  /* this affects the margin in the printer settings */
  }
 
  html{
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }
  /*去除页眉页脚*/
</style>
